<template>
 <div class="title"><div style="
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.2px;
        color: #252F37;
        ">{{ data.name }}<span style="
                                font-family: Roboto;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 13px;
                                line-height: 20px;
                                /* identical to box height, or 123% */
                                letter-spacing: 0.25px;
                                color: #6E7B87; margin-left: 10px">{{ data.tasks.length }}</span></div></div>
</template>

<script>
export default {
  name: 'group-title',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style scoped>
.title {
    direction: ltr;
}
</style>
